import React from "react";

import { LEADERS } from "../../global/js/helpers";

import "./meet-leaders-block.scss";

const MeetLeaders = () => {

    // Function to Sort Leaders So that the middle column is the longest
    const sortLeaders = (data) => {
        let columnItem = []

        for (let e = 0; e < data.leaders.length; e++) {
            if (e == data.leaders.length - 1 && Math.abs(data.leaders.length % 3) == 1) {
                columnItem.push(
                    <a key={e} className="grid-col-item" rel="noreferrer" href={data.leaders[e].link} target="_blank">

                    </a>);

                columnItem.push(
                    <a key={e + 1} className="grid-col-item" rel="noreferrer" href={data.leaders[e].link} target="_blank">
                        {data.leaders[e].name}
                    </a>);
            }
            else {
                columnItem.push(
                    <a key={e} className="grid-col-item" rel="noreferrer" href={data.leaders[e].link} target="_blank">
                        {data.leaders[e].name}
                    </a>);
            }
        }
        return columnItem;
    }

    return (
        <div className="leaders-grid">
            <h2 className="leaders-grid__title">Meet The Leaders</h2>
            <div className="leaders-grid__grid-container">
                {sortLeaders(LEADERS)}
            </div>
        </div>
    );
};

export default MeetLeaders;